import { Outlet } from 'react-router';
import Header from '../Header/Header';
import ModalBuyDonate from '../../pages/DonatePage/ModalBuyDonate/ModalBuyDonate';
import Footer from '../Footer/Footer';

function Layout() {
  return (
    <>
      <main>
        <Header />
        <Outlet />
        <ModalBuyDonate />
      </main>
      <footer>
        {' '}
        <Footer />{' '}
      </footer>
    </>
  );
}

export default Layout;
