import { FC } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { IP_ADDRESS, NAME } from '../../utils/Server';

const MainPage: FC = () => {
  const navigate = useNavigate();

  return (
    <>
      <Container fluid className="py-2">
        <Container>
          <Row>
            <Col sm={6}>
              <p
                className="d-flex align-items-center justify-content-center"
                style={{ fontSize: '5rem', letterSpacing: '-.025em' }}
              >
                MiNEX
              </p>
              <p
                className="d-flex align-items-center justify-content-center"
                style={{ fontSize: '3.0rem', letterSpacing: '-.025em', lineHeight: '1' }}
              >
                BEDROCK
              </p>
              <Row className="justify-content-center mt-4">
                <Col xs="auto">
                  <Button variant="primary" onClick={() => navigate('/start')}>
                    Начать играть
                  </Button>
                </Col>
                <Col xs="auto">
                  <Button variant="outline-secondary" onClick={() => navigate('/start')}>
                    IP: {IP_ADDRESS}
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col sm={6} className="d-flex align-items-center justify-content-center">
              <img
                src="https://p.turbosquid.com/ts-thumb/ZI/UlY413/WovdakMG/minecraftzombierigged3dmodel26/jpg/1458972852/1920x1080/fit_q87/ba2713e456bc3fa32d7675309de71fb3b28e8d6d/minecraftzombierigged3dmodel26.jpg"
                alt="Minecraft Zombie"
                style={{ maxWidth: '100%', height: 'auto' }}
              />
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default MainPage;
