import { Button, Card, Col, Container, Modal, Row, Spinner } from 'react-bootstrap';
import { FC, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { userSlice } from '../../../store/reducers/UserSlice/UserSlice';
import Form from 'react-bootstrap/Form';

const ModalBuyDonate: FC = () => {
  // Флаг, открыта ли форма
  const show = useAppSelector((state) => state.userReducer.modalBuyDonate);

  // Запись, ее данные
  const selectedData = useAppSelector((state) => state.userReducer.selectedRowBuyDonate);
  const [data, setData] = useState<any>(undefined);

  // Состояния для хранения состояний загрузки/отправки данных
  const [isPreload, setIsPreload] = useState<boolean>(false);
  const [isDataSend, setIsDataSend] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const { setShowModalBuyDonate } = userSlice.actions;

  const controller = useRef(new AbortController());

  // При окрытии формы
  useEffect(() => {
    if (show) {
      controller.current = new AbortController();

      setData({ ...selectedData });
    }
  }, [show]);

  // Обработчик
  const handleChangeDeviceRoles = (e: any) => {
    e.preventDefault();
    setIsPreload(true);
    setIsDataSend(true);

    // if (config.url_inm) {
    //   axios
    //     .patch(`${config.url_inm}v1/directories/device_roles/device_role`, data, {
    //       signal: controller?.current.signal,
    //       headers: {
    //         'Content-Type': 'application/json',
    //       },
    //     })
    //     .then(() => {
    //       successHandler('Данные успешно обновлены');
    //       dispatch(setIsReloadTable(true));
    //       handleClose();
    //     })
    //     .catch((error) => {
    //       // При преждевременном закрытии модального окна, когда запрос отправляется,
    //       // срабатывает catch и в качестве значения переменной error передает null
    //       if (error) {
    //         errorHandler(error);
    //       }
    //     })
    //     .finally(() => {
    //       setIsDataSend(false);
    //       setIsPreload(false);
    //     });
    // }
  };

  // Очистка формы
  const cleanForm = () => {
    setData({});
    setIsPreload(false);
  };

  // Обработчик закрытия формы
  const handleClose = () => {
    dispatch(setShowModalBuyDonate(false));

    // При закрытии обрыв всех запросов
    controller.current.abort();
    cleanForm();
  };

  return (
    <Modal
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title className="fs-6">{``}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-1 pb-1">
        <Container fluid>
          <Row>
            <Col className="ps-0">
              <Form id="formModal">
                <Row>
                  <Card className="d-flex justify-content-center h-100">
                    <Card.Body>
                      <Card.Title className="d-flex justify-content-center">
                        {data?.name}
                      </Card.Title>
                      <Card.Text className="d-flex justify-content-center">
                        <img
                          src={data?.imgSrc}
                          alt="Уэ-э-э"
                          style={{ maxWidth: '75%', height: 'auto' }}
                        />
                      </Card.Text>

                      <Card.Text>{data?.description}</Card.Text>

                      <Card.Text className="text-center" style={{ fontSize: '1.875rem' }}>
                        {data?.price}₽
                      </Card.Text>
                    </Card.Body>
                  </Card>
                  <Col className="py-2">
                    <Form.Group className="mb-3" as={Row}>
                      <Form.Label className="fs-6" column sm={4}>
                        Никнейм
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          type="text"
                          onChange={(e: any) => {
                            setData({
                              ...data,
                              nickname: e.target.value,
                            });
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Row className="w-100">
          <Col sm={8}></Col>
          <Col sm={4} className="ps-5">
            <Button
              variant="primary"
              onClick={handleChangeDeviceRoles}
              disabled={isDataSend}
              className="px-0 sendFormAddDataButton"
            >
              <div className="d-flex align-items-center justify-content-center">
                Оплатить&nbsp;
                {isPreload && <Spinner size="sm" style={{ color: '#fff' }} />}
              </div>
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalBuyDonate;
