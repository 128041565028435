import { FC } from 'react';
import { Accordion, Button, Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { IP_ADDRESS } from '../../utils/Server';

const PrivacyPolicyPage: FC = () => {
  const navigate = useNavigate();

  return (
    <>
      <Container fluid className="py-2">
        <Container>
          <p className="d-flex justify-content-center" style={{ fontSize: '2.25rem' }}>
            Политика конфиденциальности
          </p>
          Настоящая Политика конфиденциальности определяет, каким образом SQWEEZIX собирает,
          использует, хранит и раскрывает информацию, полученную от пользователей на веб-сайте
          sqweezix.xyz ("Сайт"). Данная политика конфиденциальности относится и к Сайту, всем
          поддоменам Сайта и всем продуктам и услугам, предлагаемым SQWEEZIX. Эта страница содержит
          сведения о том, какую информацию мы или третьи лица могут получать, когда Вы пользуетесь
          нашим Сайтом. Мы надеемся, что эти сведения помогут Вам принимать осознанные решения в
          отношении предоставляемой нам информации о себе. Настоящая Политика конфиденциальности
          распространяется непосредственно на этот Сайт и на информацию, получаемую с его помощью.
          Она не распространяется ни на какие другие сайты и не применима к веб-сайтам третьих лиц,
          которые могут содержать упоминание о нашем Сайте и с которых могут делаться ссылки на
          Сайт, а так же ссылки с этого Сайта на другие сайты сети Интернет.
          <p className="d-flex justify-content-center border-b" style={{ fontSize: '1.875rem' }}>
            Получаемая информация
          </p>
          Когда Вы посещаете Сайт, мы определяем IP адрес, имя домена с которого Вы к нам пришли
          (например, "yandex.ru") и страну регистрации данного ip , а так же фиксируем все переходы
          посетителей с одной страницы Сайта на другую. Сведения, которые мы получаем на Сайте,
          могут быть использованы для того, чтобы облегчить пользование Сайтом. Сайт собирает только
          общую информацию, которую Ваш браузер предоставляет добровольно при посещении Сайта. Сайт
          применяет стандартную технологию "cookies" ("куки") для настройки стилей отображения Сайта
          под параметры экрана монитора. "Куки" представляет собой данные с веб-сайта, который
          сохраняет на жестком диске Вашего же компьютера. В "cookies" содержится информация,
          которая может быть необходимой для настройки Сайта, - для сохранения Ваших установок
          вариантов просмотра и сбора статистической информации по Сайту, т.е. какие страницы Вы
          посетили, что было загружено, имя домена интернет-провайдера и страна посетителя, а также
          адреса сторонних веб-сайтов, с которых совершен переход на Сайт и далее. Также данную
          технологию использует установленные на Сайте счетчики компании Yandex/Rambler/Google и
          т.п. Технология "Cookies" не содержит никаких личных сведений относительно Вас. Чтобы
          просматривать материал без "cookies", Вы можете настроить свой браузер таким образом,
          чтобы она не принимала "cookies", либо уведомляла Вас об их посылке (настройки браузеров
          различны, поэтому советуем Вам получить справку в разделе "Помощь" и выяснить как изменить
          установки браузера по "cookies"). Подробно о работе куки файлов Вы можете прочитать здесь:
          http://ru.wikipedia.org/wiki/HTTP_cookie Кроме того, Сайт использует стандартные
          возможности (журналы) веб-сервера для подсчета количества посетителей и оценки технических
          возможностей хост-сервера, рейтинги и счетчики посещаемости от сторонних организаций
          (yandex.ru, top100.rambler.ru, top.mail.ru и др.). Мы используем эту информацию для того,
          чтобы определить сколько человек посещает Сайт и расположить страницы наиболее удобным для
          пользователей способом, обеспечить соответствие Сайта с используемыми Вами браузерам, и
          сделать содержание Сайта максимально полезным для посетителей. Мы записываем сведения по
          перемещениям на Сайте, но не об отдельных посетителях Сайта, так что никакая конкретная
          информация относительно Вас лично не будет сохраняться или использоваться Администрацией
          Сайта без Вашего согласия. Также мы можем собирать личную идентификационную информацию от
          пользователей, когда пользователь посещает наш Сайт, регистрируется на Сайте, оформляет
          заказ, заполняет формы и в связи с другой активностью на Сайте. Пользователя могут
          попросить при необходимости указывать имя, электронный адрес, номер телефона, данные
          кредитной карты. Пользователи могут, однако, посещать наш Сайт анонимно. Мы собираем
          личную идентификационную информацию пользователей, только если они добровольно
          предоставляют нам такую информацию. Пользователи всегда могут отказаться в предоставлении
          личной идентификационной информации, за исключением случаев, когда это может помешать
          пользоваться отдельными функциями Сайта.
          <p className="d-flex justify-content-center border-b" style={{ fontSize: '1.875rem' }}>
            Как мы используем собранную информацию
          </p>
          SQWEEZIX может собирать и использовать личную информацию пользователей для следующих
          целей: - Для улучшения обслуживания клиентов. Предоставляемая вами информация помогает нам
          реагировать на запросы клиентов более эффективно; - Чтобы персонализировать
          пользовательский опыт. Мы можем использовать информацию для определения кто из посетителей
          Сайта наиболее заинтересован в услугах и ресурсах предоставляемых на нашем Сайте; - Для
          улучшения нашего Сайта. Мы можем использовать обратную связь, которую Вы предоставляете,
          чтобы улучшить наши продукты и услуги; - Для обработки платежей. Мы можем использовать
          информацию о пользователях при оформлении заказа для оформления платежей и только для
          этого. Мы не делимся этой информацией с третьими лицами, за исключением тех случаев, когда
          необходимо для предоставления услуг; - Чтобы отправлять пользователям информацию, которую
          они согласились получать на темы, которые, как мы думаем, будут представлять для них
          интерес; - Чтобы отправить периодические сообщения электронной почты, которые могут
          включать новости компании, обновления, информацию о продуктах и услугах и т.д. Если
          пользователь хотел бы отказаться от получения последующих писем, мы включаем подробное
          описание инструкции по тому, как отписаться в нижней части каждой электронной почты или
          пользователь может связаться с нами через наш Сайт.{' '}
          <p className="d-flex justify-content-center border-b" style={{ fontSize: '1.875rem' }}>
            Как мы защищаем вашу информацию
          </p>
          Мы принимаем соответствующие меры безопасности по сбору, хранению и обработке собранных
          данных для защиты их от несанкционированного доступа, изменения, раскрытия или уничтожения
          Вашей личной информации (имя пользователя, пароль, информация транзакции и данные,
          хранящиеся на нашем Сайте).
          <p className="d-flex justify-content-center border-b" style={{ fontSize: '1.875rem' }}>
            Общий доступ к личной информации
          </p>
          Мы не продаем, не обмениваем или не даем в аренду личную информацию пользователей. Мы
          можем предоставлять общие агрегированные демографические данные, не связанные с личной
          информацией, нашими партнерами и рекламодателями для целей, описанных выше. Мы можем
          использовать сторонних поставщиков услуг, чтобы помочь нам управлять нашим бизнесом и
          Сайтом или управлять деятельностью от нашего имени, например, проведение рассылки или
          статистические и иные исследования. Мы можем делиться этой информацией с этими третьими
          лицами для ограниченных целей при условии, что Вы дали нам соответствующие разрешения.
          <p className="d-flex justify-content-center border-b" style={{ fontSize: '1.875rem' }}>
            Изменения в политике конфиденциальности
          </p>
          SQWEEZIX имеет право по своему усмотрению обновлять данную политику конфиденциальности в
          любое время. В этом случае мы опубликуем уведомление на главной странице нашего Сайта и
          сообщим Вам об этом по электронной почте. Мы рекомендуем пользователям регулярно проверять
          эту страницу для того, чтобы быть в курсе любых изменений о том, как мы защищаем личную
          информацию, которую мы собираем. Используя Сайт, Вы соглашаетесь с принятием на себя
          ответственности за периодическое ознакомление с Политикой конфиденциальности и изменениями
          в ней.
          <p className="d-flex justify-content-center border-b" style={{ fontSize: '1.875rem' }}>
            Ваше согласие с этими условиями
          </p>
          Используя этот Сайт, Вы выражаете свое согласие с этой политикой. Если Вы не согласны с
          этой политикой, пожалуйста, не используйте наш Сайт. Ваше дальнейшее использование Сайта
          после внесения изменений в настоящую политику будет рассматриваться как Ваше согласие с
          этими изменениями.
          <p className="d-flex justify-content-center border-b" style={{ fontSize: '1.875rem' }}>
            Отказ от ответственности
          </p>
          Помните, политика конфиденциальности при посещении сторонних Сайтов третьих лиц, не
          подпадает под действия данного документа. Администрация Сайта не несет ответственности за
          действия других веб-сайтов.
        </Container>
      </Container>
    </>
  );
};

export default PrivacyPolicyPage;
